import React from "react"

const DrawerButton = ({ buttonText, handleToggle }) => {
    return (
        <button className="btn btn--outlined drawer-button" onClick={handleToggle}>
            {buttonText}
        </button>
    )
}

export default DrawerButton
