import { get, post, put, remove as httpRemove, postAbortable } from "../Services/http"
import { toggleShoppingListDrawer } from "./Drawer.action"
import { toggle as toggleCart, load as loadCart } from "./Cart.action"
import { catchError } from "./Error.action"

export const SHOPPINGLIST_RECEIVE = "SHOPPINGLIST_RECEIVE"
export const SHOPPINGLIST_ERROR = "SHOPPINGLIST_ERROR"
export const SHOPPINGLIST_CHANGE_MODE = "SHOPPINGLIST_CHANGE_MODE"
export const SHOPPINGLIST_PRODUCT_SEARCH_QUERY = "SHOPPINGLIST_PRODUCT_SEARCH_QUERY"

const rootRoute = "/api/shoppinglist"

export const changeMode = (mode) => ({
    type: SHOPPINGLIST_CHANGE_MODE,
    payload: {
        mode,
    },
})

export const query = (id, mode = "list") => (dispatch) => {
    return get(`${rootRoute}/getall/${id}`)
        .then((response) => response.json())
        .then((result) => {
            dispatch(receive(result, mode))
        })
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))
}
export const queryOne = (id, mode = "details") => (dispatch) => {
    return get(`${rootRoute}/${id}`)
        .then((response) => response.json())
        .then((result) => {
            dispatch(receiveOne(result, mode))
        })
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))
}
export const remove = (id) => (dispatch) =>
    httpRemove(`${rootRoute}/delete/${id}`)
        .then(() => dispatch(query()))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const add = (shoppinglist) => (dispatch) =>
    post(`${rootRoute}/create`, shoppinglist)
        .then(() => dispatch(query(shoppinglist.projectId)))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const edit = (shoppinglist) => (dispatch) =>
    put(`${rootRoute}/update/${shoppinglist.id}`, shoppinglist)
        .then(() => dispatch(queryOne(shoppinglist.id, "details")))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const copyShoppingListToCart = ({ shoppingListId }) => {
    if (shoppingListId) {
        return (dispatch) => {
            return post(`${rootRoute}/copyShoppingListToCart`, shoppingListId)
                .then(() => dispatch(queryOne(shoppingListId, "details")))
                .then(() => dispatch(loadCart()))
                .then(() => dispatch(toggleCart()))
                .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))))
        }
    }
}

export const addItemToExistingList = (shoppinglistId, item) => (dispatch) =>
    post(`${rootRoute}/${shoppinglistId}/items/create`, item)
        .then(() => dispatch(queryOne(shoppinglistId, "details")))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const addItemToNewList = (list) => (dispatch) =>
    post(`${rootRoute}/createwithitem`, list)
        .then((response) => response.json())
        .then((result) => dispatch(queryOne(result.id, "details")))
        .then(() => dispatch(toggleShoppingListDrawer()))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const editItem = (shoppinglistId, item) => (dispatch) =>
    put(`${rootRoute}/${shoppinglistId}/items/update/${item.id}`, item)
        .then(() => dispatch(queryOne(shoppinglistId, "details")))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const removeItem = (shoppinglistId, itemId) => (dispatch) =>
    httpRemove(`${rootRoute}/${shoppinglistId}/items/delete/${itemId}`)
        .then(() => dispatch(queryOne(shoppinglistId, "details")))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const searchProduct = (q, signal) => (dispatch, getState) => {
    dispatch({
        type: SHOPPINGLIST_PRODUCT_SEARCH_QUERY,
        payload: {
            query: q,
        },
    })
    return postAbortable("/api/quickSearch", q, signal)
        .then((response) => response.json())
        .then((result) => dispatch(setSearchResult(result)))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))
}

const receive = (list, mode) => ({
    type: SHOPPINGLIST_RECEIVE,
    payload: {
        list,
        mode,
    },
})

const receiveOne = (shoppingList, mode) => ({
    type: SHOPPINGLIST_RECEIVE,
    payload: {
        shoppingList,
        mode,
    },
})

export const setError = (error) => ({
    type: SHOPPINGLIST_ERROR,
    payload: {
        error,
    },
})

const setSearchResult = (searchResult) => ({
    type: SHOPPINGLIST_RECEIVE,
    payload: {
        searchResult,
    },
})
