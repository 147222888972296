import { PERSON_RECEIVE, PERSON_ERROR, PERSON_CHANGE_MODE, PERMISSION_RECIEVE } from "../Actions/Person.action"
import { ADDRESS_RECEIVE, ADDRESS_ERROR, ADDRESS_CHANGE_MODE } from "../Actions/Address.action"
import { PROJECT_RECEIVE, PROJECT_ERROR, PROJECT_CHANGE_MODE } from "../Actions/Project.action"
import { SHOPPINGLIST_RECEIVE, SHOPPINGLIST_ERROR, SHOPPINGLIST_CHANGE_MODE, SHOPPINGLIST_PRODUCT_SEARCH_QUERY } from "../Actions/ShoppingList.action"
import { SHOW_SHOPPINGLIST_DRAWER } from "../Actions/Drawer.action"
import { person as personReducer } from "./Person.reducer"
import { address as addressReducer } from "./Address.reducer"
import { project as projectReducer } from "./Project.reducer"
import { shoppinglist as shoppingListReducer } from "./ShoppingList.reducer"

const defaultState = {
    persons: {},
    addresses: {},
    projects: {},
    shoppingLists: {},
    permission: "",
}

export const myPage = (state = defaultState, action) => {
    const { type } = action
    switch (type) {
        case PERMISSION_RECIEVE:
            return {
                ...state,
                permission: action.payload.permission,
            }
        case PERSON_RECEIVE:
        case PERSON_ERROR:
        case PERSON_CHANGE_MODE:
            return {
                ...state,
                persons: personReducer(state.persons, action),
            }
        case ADDRESS_RECEIVE:
        case ADDRESS_ERROR:
        case ADDRESS_CHANGE_MODE:
            return {
                ...state,
                addresses: addressReducer(state.addresses, action),
            }
        case PROJECT_RECEIVE:
        case PROJECT_ERROR:
        case PROJECT_CHANGE_MODE:
            return {
                ...state,
                projects: projectReducer(state.projects, action),
            }
        case SHOPPINGLIST_RECEIVE:
        case SHOPPINGLIST_ERROR:
        case SHOPPINGLIST_CHANGE_MODE:
        case SHOW_SHOPPINGLIST_DRAWER:
        case SHOPPINGLIST_PRODUCT_SEARCH_QUERY:
            return {
                ...state,
                shoppingLists: shoppingListReducer(state.shoppingLists, action),
            }
        default:
            return state
    }
}
