import { PROJECT_RECEIVE, PROJECT_ERROR, PROJECT_CHANGE_MODE } from "../Actions/Project.action"
import { error as errorReducer } from "./Error.reducer"

const defaultState = {
    list: [],
    mode: "list",
    errors: {},
}

export const project = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case PROJECT_RECEIVE:
        case PROJECT_CHANGE_MODE:
            return {
                ...state,
                errors: {},
                ...payload,
            }
        case PROJECT_ERROR:
            return {
                ...state,
                errors: errorReducer(state.errors, action),
            }
        default:
            return state
    }
}
