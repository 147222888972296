import { get, post, put, remove as httpRemove } from "../Services/http"
import { catchError } from "./Error.action"

export const PROJECT_RECEIVE = "PROJECT_RECEIVE"
export const PROJECT_ERROR = "PROJECT_ERROR"
export const PROJECT_CHANGE_MODE = "PROJECT_CHANGE_MODE"

const rootRoute = "/api/project"

export const changeMode = (mode) => ({
    type: PROJECT_CHANGE_MODE,
    payload: {
        mode,
    },
})

export const query = (mode = "list") => (dispatch) => {
    return get(rootRoute + "/getall")
        .then((response) => response.json())
        .then((result) => {
            dispatch(receive(result, mode))
        })
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))
}

export const remove = (id) => (dispatch) =>
    httpRemove(`${rootRoute}/delete/${id}`)
        .then(() => dispatch(query()))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const add = (project) => (dispatch) =>
    post(`${rootRoute}/create`, project)
        .then(() => dispatch(query()))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

export const edit = (project) => (dispatch) =>
    put(`${rootRoute}/update/${project.id}`, project)
        .then(() => dispatch(query()))
        .catch((ex) => dispatch(catchError(ex, (error) => setError(error))))

const receive = (list, mode) => ({
    type: PROJECT_RECEIVE,
    payload: {
        list,
        mode,
    },
})

export const setError = (error) => ({
    type: PROJECT_ERROR,
    payload: {
        error,
    },
})
