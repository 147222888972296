export const SHOW_SHOPPINGLIST_DRAWER = "SHOW_SHOPPINGLIST_DRAWER"

export const toggleShoppingListDrawer = () => (dispatch, getState) => {
    const state = getState()
    const currentState = state.myPage?.shoppingLists?.showDrawer
    dispatch(show(!currentState))
}

const show = (visible) => ({
    type: SHOW_SHOPPINGLIST_DRAWER,
    payload: {
        showDrawer: visible,
    },
})
