import React from "react"
import { translate } from "../../Services/translation"

const DrawerFooter = ({ onClick, onClose }) => {
    return (
        <div className="drawer__footer">
            <button type="button" className="btn btn--full-width h-mb-sm" onClick={onClick}>
                {translate("general.save")}{" "}
            </button>
            <button type="button" className="btn btn--outlined btn--full-width" onClick={onClose}>
                {translate("general.cancel")}{" "}
            </button>
        </div>
    )
}

export default DrawerFooter
