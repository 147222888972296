import { SHOPPINGLIST_RECEIVE, SHOPPINGLIST_ERROR, SHOPPINGLIST_CHANGE_MODE, SHOPPINGLIST_PRODUCT_SEARCH_QUERY } from "../Actions/ShoppingList.action"
import { SHOW_SHOPPINGLIST_DRAWER } from "../Actions/Drawer.action"
import { error as errorReducer } from "./Error.reducer"

const defaultState = {
    list: [],
    mode: "list",
    errors: {},
    showDrawer: false,
    shoppingList: {},
    query: "",
    searchResult: [],
}

export const shoppinglist = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case SHOPPINGLIST_PRODUCT_SEARCH_QUERY:
        case SHOPPINGLIST_RECEIVE:
        case SHOPPINGLIST_CHANGE_MODE:
            return {
                ...state,
                errors: {},
                ...payload,
            }
        case SHOPPINGLIST_ERROR:
            return {
                ...state,
                errors: errorReducer(state.errors, action),
            }
        case SHOW_SHOPPINGLIST_DRAWER:
            return {
                ...state,
                showDrawer: payload.showDrawer,
            }
        default:
            return state
    }
}
