import React, { Fragment, useEffect, useState } from "react"
import { translate } from "../Services/translation"
import { toggleShoppingListDrawer } from "../Actions/Drawer.action"
import { useDispatch, useSelector } from "react-redux"
import { query as getProjects } from "../Actions/Project.action"
import { query as getShoppingLists, addItemToExistingList, addItemToNewList } from "../Actions/ShoppingList.action"
import Drawer from "./Drawer/Drawer"
import DrawerHeader from "./Drawer/Drawer.header"
import DrawerBody from "./Drawer/Drawer.body"
import DrawerFooter from "./Drawer/Drawer.footer"

const AddToShoppingList = ({}) => {
    const showShoppingListDrawer = useSelector((state) => state.myPage.shoppingLists.showDrawer)
    const projects = useSelector((state) => state.myPage.projects.list)
    const shoppingLists = useSelector((state) => state.myPage.shoppingLists.list)
    const dispatch = useDispatch()

    const [selectedShoppingList, setSelectedShoppingList] = useState("")
    const [selectedProject, setSelectedProject] = useState("")
    const [newShoppingList, setNewShoppingList] = useState({})
    const [isCreatingNewList, setIsCreatingNewList] = useState(false)
    const productId = document.getElementById("addToShoppingList").getAttribute("data-productid")
    const productName = document.getElementById("addToShoppingList").getAttribute("data-productname")
    const qtyInputId = document.getElementById("addToShoppingList").getAttribute("data-qtyinputid")
    const qty = document.getElementById(qtyInputId)?.value

    useEffect(() => {
        dispatch(getProjects())
    }, [])

    useEffect(() => {
        if (projects && projects.length > 0) {
            setSelectedProject(projects[0].id)
        }
    }, [projects])

    useEffect(() => {
        if (shoppingLists && shoppingLists.length === 0) {
            setIsCreatingNewList(true)
        } else {
            setIsCreatingNewList(false)
            setSelectedShoppingList(shoppingLists?.[0]?.id)
        }
    }, [shoppingLists])

    useEffect(() => {
        if (selectedProject) dispatch(getShoppingLists(selectedProject))
    }, [dispatch, selectedProject])

    const handleChange = (propName, value) => {
        setNewShoppingList((prevState) => ({
            ...prevState,
            projectId: selectedProject,
            [propName]: value,
        }))
    }

    const handleShoppingListChange = (event) => {
        const value = event.target.value
        setSelectedShoppingList(value)
        setIsCreatingNewList(value === "new")
    }

    const handleAddItem = () => {
        let item = {
            id: productId,
            name: productName,
            quantity: qty,
        }
        if (isCreatingNewList) {
            let newList = { ...newShoppingList, items: [item] }
            dispatch(addItemToNewList(newList))
        } else {
            dispatch(addItemToExistingList(selectedShoppingList, item))
        }
    }

    return (
        <>
            <Drawer
                buttonText={translate("shoppingListDrawer.button.add")}
                toggleDrawer={() => dispatch(toggleShoppingListDrawer())}
                showDrawer={showShoppingListDrawer}
            >
                <Fragment>
                    <DrawerHeader title={translate("shoppingListDrawer.title")} onClose={() => dispatch(toggleShoppingListDrawer())} />
                    <DrawerBody>
                        <form>
                            <p>{translate("shoppingListDrawer.text")}</p>
                            <label className="form__label" htmlFor="shoppingListDrawerProject">
                                {translate("shoppingListDrawer.project")}
                            </label>
                            <select
                                className="form__input"
                                id="shoppingListDrawerProject"
                                autoComplete="project"
                                value={selectedProject ?? ""}
                                label={translate("shoppingListDrawer.project")}
                                onChange={(event) => setSelectedProject(event.target.value)}
                            >
                                {projects?.map((project) => (
                                    <option key={project.id} value={project.id}>
                                        {project.name}
                                    </option>
                                ))}
                            </select>
                            <label className="form__label" htmlFor="shoppingListDrawerList">
                                {translate("shoppingListDrawer.shoppingList")}
                            </label>
                            <select
                                className="form__input"
                                id="shoppingListDrawerList"
                                autoComplete="shoppingList"
                                value={selectedShoppingList ?? ""}
                                onChange={(event) => handleShoppingListChange(event)}
                            >
                                {shoppingLists?.map((shoppingList) => (
                                    <option key={shoppingList.id} value={shoppingList.id}>
                                        {shoppingList.name}
                                    </option>
                                ))}
                                <option value="new">{translate("shoppingListDrawer.createNewShoppingList")}</option>
                            </select>
                            {isCreatingNewList && (
                                <div>
                                    <div className="h-font-display-xs h-mt-md h-mb-sm">{translate("shoppingListDrawer.newShoppingList")}</div>
                                    <label className="form__label" htmlFor="shoppingListDrawerName">
                                        {translate("mypage.shoppingList.name")}{" "}
                                    </label>
                                    <input
                                        type="text"
                                        id="shoppingListDrawerName"
                                        className="form__input"
                                        placeholder={translate("mypage.shoppingList.name")}
                                        value={newShoppingList.name || ""}
                                        onChange={(event) => handleChange("name", event.target.value)}
                                    />
                                    <label className="form__label" htmlFor="shoppingListDrawerDescription">
                                        {translate("mypage.shoppingList.description")}{" "}
                                    </label>
                                    <textarea
                                        className="form__input"
                                        id="shoppingListDrawerDescription"
                                        placeholder={translate("mypage.shoppingList.description")}
                                        value={newShoppingList.description || ""}
                                        onChange={(event) => handleChange("description", event.target.value)}
                                    />
                                </div>
                            )}
                        </form>
                    </DrawerBody>
                    <DrawerFooter onClick={() => handleAddItem()} onClose={() => dispatch(toggleShoppingListDrawer())} />
                </Fragment>
            </Drawer>
        </>
    )
}

export default AddToShoppingList
