import React from "react"

const DrawerHeader = ({ title, onClose }) => {
    return (
        <div className="drawer__header row margin-bottom-1">
            <div className="columns">
                <label className="drawer__title">{title}</label>
            </div>
            <div className="columns shrink">
                <button type="button" className="drawer__close-button" onClick={onClose}></button>
            </div>
        </div>
    )
}

export default DrawerHeader
